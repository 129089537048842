import axios from 'axios';
import { fetchEventData } from 'fetch-sse';
import { handleToolCall } from './tools';
import { message } from '../message';

let token: string = '';
const BASEURL = process.env.AI_SERVER || '.';
const api = axios.create({
  baseURL: BASEURL,
});

// 响应拦截器
api.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    return response;
  },
  (error) => {
    message.destroy();
    // 对响应错误做点什么
    if (error.response) {
      // 在这里根据状态码或其他条件向用户显示相应的提示信息
      if (error.response.status === 404) {
        message.error('请求的资源不存在！');
      } else {
        message.error('发生了一个错误，请稍后重试。');
      }
    } else if (error.code !== 'ERR_CANCELED') {
      // 在这里处理网络错误的提示
      message.error('网络错误，请检查您的网络连接并重试。');
    }
    return Promise.reject(error);
  }
);
api.interceptors.request.use((config) => {
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export interface AgentToolCall {
  callId: string;
  toolName: string;
  params: any;
  actionName: string;
}

export interface AIAnswer {
  answer: string;
  sid: string;
  tags?: { text: string }[];
}

export async function execAgent(
  ctrl?: AbortController,
  question?: string,
  sid?: string,
  onAction?: (action: AgentToolCall) => Promise<void>,
  onMessage?: (message: string) => void,
  onSession?: (sid: string) => Promise<void>
): Promise<AIAnswer> {
  let ret: any;
  let err;
  await fetchEventData(`${api.defaults.baseURL}/api/agent/exec`, {
    method: 'POST',
    signal: ctrl?.signal,
    headers: {
      'Content-Type': 'application/json',
      // 'Authorization': 'Bearer token'
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      // 添加一个唯一的自定义头部，包含当前时间戳
      'X-Custom-No-Cache': new Date().getTime().toString(),
    },
    data: {
      question,
      sid,
    } as any,
    onError(error) {
      err = error;
    },
    async onMessage(ev) {
      const data = JSON.parse(ev!.data);
      console.log(ev?.event, data);
      if (ev?.event === 'TOOLCALL') {
        onAction && (await onAction(data));
        await handleToolCall(data);
      }
      if (ev?.event === 'MESSAGE') {
        onMessage?.(data.content);
      }
      if (ev?.event === 'SESSION') {
        await onSession?.(data);
      }
      if (ev?.event === 'DONE') {
        ret = data;
      }
    },
  });
  if (err) {
    throw new Error(err);
  }
  return ret;
}

export async function getTags(sid: string) {
  const res = await api.get(`/api/agent/tags?sid=${sid}`);
  return res.data;
}

export async function sendLike(mid: number, like = true) {
  await api.post(`/api/agent/${like ? 'like' : 'dislike'}?mid=${mid}`);
}

export async function toolCallback(
  toolName: string,
  callId: string,
  data: any
) {
  console.log('TOOLCALLBACK', { toolName, callId, data });
  await api.post(`/api/tool/${toolName}`, { callId, data });
}
