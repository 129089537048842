import { CID, create } from 'kubo-rpc-client';
import { db, Note } from './Data';
import { contentEncrypted, contentDecrypted } from './utils';

let client = create(process.env.IPFS_SERVER);

async function pullBook(bookId: number) {
  const book = (await db.books.get(bookId))!;
  if (!book.cid || book.reason === 'success') return;
  try {
    const { value } = await client.dag.get(CID.parse(book.cid));
    if (value?.notes) {
      for (const cid of value.notes) {
        await pullNote(cid, bookId);
      }
    }
    book.title = value.title;
    book.reason = 'success';
  } catch (err) {
    book.reason = 'cidconflict';
    console.log(err);
  }
  await db.syncBook(book);
}

async function pullNote(cid: string, bookId: number) {
  try {
    let note = await db.notes.where('cid').equals(cid).first();
    if (note && note.reason === 'success') return;
    const { value } = await client.dag.get(CID.parse(cid));
    const key = await db.getActiveKey();
    value.content = key ? contentDecrypted(value.content, key) : value.content;
    note = await db.notes.where('name').equals(value.name).first();
    if (note) {
      Object.assign(note, value, {
        cid,
        reason: 'success',
      });
      await db.syncNote(note);
    } else {
      await db.addNote({
        ...value,
        createAt: value.createAt ? new Date(value.createAt) : undefined,
        bookId,
        cid,
        reason: 'success',
      });
    }
  } catch (err) {
    console.log(`pull cid, bookId: ${cid}, ${bookId}`, err);
  }
}

async function pushBook(bookId: number) {
  const book = (await db.books.get(bookId))!;
  const notes = await db.notes
    .where('bookId')
    .equals(bookId)
    .filter((it) => !!it.cid && it.enabled)
    .toArray();
  try {
    const cid = await client.dag.put({
      name: book!.name,
      title: book!.title || '',
      notes: notes.map((it) => it.cid),
    });
    book.cid = cid.toString();
    book.reason = 'success';
    console.log('BOOK CID:', book.cid);
  } catch (err) {
    book.reason = 'cidconflict';
    console.log(err);
  }
  await db.syncBook(book);
}

async function pushNote(note: Note) {
  const key = await db.getActiveKey();
  const obj = {
    name: note.name,
    content: key ? contentEncrypted(note.content, key) : note.content,
    type: note.type || 'text',
    createAt: note.createAt?.toISOString(),
  };
  try {
    const cid = await client.dag.put(obj);
    note.cid = cid.toString();
    note.reason = 'success';
    console.log('NOTE CID:', note.cid);
  } catch (err) {
    note.reason = 'cidconflict';
    console.log(err);
  }
  await db.syncNote(note);
}

export async function connect() {
  const node = await db.getActaiveNode();
  if (node) {
    client = create(node.url);
  }
  const timers: any = {};
  db.table('nodes').hook('updating', async (modifies) => {
    if ('url' in modifies) {
      client = create(modifies.url || process.env.IPFS_SERVER);
    }
  });
  db.table('notes').hook('updating', async (modifies, _pk, note) => {
    if (
      'content' in modifies ||
      'type' in modifies ||
      ('reason' in modifies && modifies.reason === '')
    ) {
      clearTimeout(timers[note.id]);
      const timer = setTimeout(() => {
        pushNote(note);
        delete timers[note.id];
      }, 5000);
      timers[note.id] = timer;
    }
    if ('cid' in modifies || 'enabled' in modifies) {
      setTimeout(() => {
        pushBook(note.bookId);
      }, 0);
    }
  });
  db.table('books').hook('updating', async (modifies: any, _pk, book) => {
    if ('cid' in modifies || ('reason' in modifies && modifies.reason === '')) {
      setTimeout(() => {
        pullBook(book.id);
      }, 0);
    }
  });
  db.notes
    .filter((it) => !it.syncAt || !it.updateAt || it.syncAt < it.updateAt)
    .each(async (note) => {
      clearTimeout(timers[note.id!]);
      const timer = setTimeout(() => {
        pushNote(note);
        delete timers[note.id!];
      }, 5000);
      timers[note.id!] = timer;
    });
}
