import axios from 'axios';
import { message } from './message';

let token: string;
const BASEURL = process.env.RELAY_SERVER || '.';
export const api = axios.create({
  baseURL: BASEURL + '/data/',
});
const appName = process.env.APP_NAME || 'jianguoke.notebook';

// 响应拦截器
api.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    return response;
  },
  (error) => {
    message.destroy();
    // 对响应错误做点什么
    if (error.response) {
      // 在这里根据状态码或其他条件向用户显示相应的提示信息
      if (error.response.status === 404) {
        return;
      } else 
      if (error.response.status === 401) {
        message.error('未授权，请重新登录！');
      } else if (error.response.status === 403) {
        message.error('禁止访问！');
      } else {
        message.error(
          error.response.data?.error || '发生了一个错误，请稍后重试。'
        );
      }
      return;
    } else if (error.code !== 'ERR_CANCELED') {
      if (error.config.url.endsWith('/behavior')) {
        return;
      }
      // 在这里处理网络错误的提示
      message.error('网络错误，请检查您的网络连接并重试。');
      return;
    }
    return Promise.reject(error);
  }
);
api.interceptors.request.use((config) => {
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export function setToken(jwtToken: string) {
  token = jwtToken;
}

export async function pullRelay(name: string) {
  const res = await api.get(`/${appName}/${name}`);
  return res?.data;
}

export async function pushRelay(name: string, data: any) {
  const res = await api.post(`/${appName}/${name}`, data);
  return res?.data;
}

export async function deleteRelay(name: string) {
  const res = await api.delete(`/${appName}/${name}`);
  return res?.data;
}
