import { db } from '../Data';
import { AgentToolCall, toolCallback } from './api';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import rehypeStringify from 'rehype-stringify';

function markdownToHtml(markdownText: string) {
  const processor = unified()
    .use(remarkParse)
    .use(remarkRehype)
    .use(rehypeStringify);
  const result = processor.processSync(markdownText).toString();
  return result;
}

function htmlToText(html: string) {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || '';
}

export const handleToolCall = async (message: AgentToolCall) => {
  let data: any = null;
  try {
    switch (message.toolName) {
      case 'read_note':
        data = htmlToText((await db.getActiveNote())?.content || '未找到笔记');
        break;
      case 'write_note':
        const id = (await db.getActiveNote())?.id;
        if (id) {
          await db.upsertNote(markdownToHtml(message.params.content || ''), id);
          data = '笔记已修改成功';
        } else {
          data = '笔记不存在无法修改';
        }
        break;
      case 'exe_command':
        const { command, args } = message.params;
        const editor = (window as any).editor;
        if (command && editor[command]) {
          try {
            if (typeof editor[command] === 'function') {
              data = editor[command].call(editor, ...args);
            } else {
              data = editor[command];
            }
          } catch (err: any) {
            data = `命令执行失败,${err.message}`;
          }
        } else {
          data = '不支持的命令';
        }
        break;
      case 'find_note':
        const query = message.params;
        const notes = await db.findNote(query);
        data = {
          notes,
          total: query.total ? await db.notes.count() : undefined,
        };
        break;
      case 'login':
        const user = await db.getUser();
        if (!user) {
          (window as any).showLogin();
          data = '已经显示登录页面请输入用户登录信息';
        } else {
          data = '已登录为:' + user.name;
        }
        break;
      case 'logout':
        await db.logout();
        data = '用户已经退出';
        break;
      case 'install_desktop':
        (window as any).addToDesktop();
        data = '已经显示安装窗口请确认安装';
        break;
      default:
        data = '暂不支持' + message.toolName + '此指令';
        break;
    }
  } catch (err) {
    data = (err as Error).message || '执行失败';
  }
  await toolCallback(message.toolName, message.callId, data).catch(
    console.error
  );
};
