import Markdown from 'react-markdown';
import { useEffect, useState } from 'react';

const TIMEOUT = 20; // 每个字之间的延迟时间（毫秒）

export const TypeWriter = ({
  text,
  force = false,
  enabled = true,
}: {
  text: string;
  force?: boolean;
  enabled?: boolean;
}) => {
  const [end] = useState(enabled);
  const [displayText, setDisplayText] = useState(!enabled ? text : '');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (force ? enabled : end) {
      if (index < text.length) {
        const timer = setTimeout(() => {
          setDisplayText(text.substring(0, index + 1));
          if (index < text.length - 1) {
            setIndex(index + 1);
          }
        }, TIMEOUT); // 每个字之间的延迟时间（毫秒）

        return () => clearTimeout(timer);
      }
    } else {
      setDisplayText(text);
    }
  }, [index, text]);

  return <Markdown>{displayText.replaceAll('\\n', '\n')}</Markdown>;
};
