import { Button, Space, Popconfirm, message } from 'antd';
import { trackClick } from '../tracker';
import { useEffect, useState } from 'react';
import { db } from '../Data';
import { deleteRelay, pullRelay, pushRelay, setToken } from '../Relay';
import { useLiveQuery } from 'dexie-react-hooks';

export function UserSync() {
  const folders = useLiveQuery(
    () => db.books.filter((it) => it.enabled).toArray(),
    []
  );
  const keys = useLiveQuery(() => db.keys.toArray(), []);
  const [syncFolders, setSyncFolders] = useState<any[]>();
  const [syncDataKeys, setSyncKeys] = useState<any[]>([]);
  const [error, setError] = useState<Error | null>();
  const user = useLiveQuery(() => db.getUser(), []);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setToken(user?.token || '');
  }, [user?.token]);

  async function fetchRemoteData() {
    const remotes = (await pullRelay('folders')) || [];
    remotes.forEach((book: any) => {
      // 和本地hash不一致都是需要同步的
      book.isRemote = true;
      book.isSyncing = folders?.every((it) => it.cid !== book.cid);
    });
    // 追加上本地的
    const newFolders = remotes.concat(
      folders
        ?.filter((local) =>
          remotes?.every((remote: any) => remote.cid !== local.cid)
        )
        .map((it) => {
          return { ...it, isSyncing: true };
        })
    );
    setSyncFolders(newFolders);
    await fetchKeys();
  }

  async function startSyncData() {
    const options = await db.getOptions();
    trackClick('sync_user', '同步数据', options?.clientId);
    for (const remoteFolder of syncFolders?.filter(
      (it) => it.isSyncing && it.isRemote
    ) || []) {
      const localFolder = folders?.find((it) => it.name === remoteFolder.name);
      if (!localFolder) {
        // 本地没有的需要增加
        const id = await db.addBook(
          remoteFolder.name,
          remoteFolder.title,
          remoteFolder.createAt
        );
        await db.updateBookCID(id, remoteFolder.cid);
      } else {
        await db.updateBookCID(localFolder?.id!, remoteFolder.cid);
      }
    }
    if (syncFolders?.some((it) => it.isSyncing && !it.isRemote)) {
      await pushRelay(
        'folders',
        syncFolders!.map(({ name, title, cid, createAt }) => ({
          name,
          title,
          cid,
          createAt,
          clientId: options?.clientId,
        }))
      );
    }
    await syncKeys();
  }

  async function fetchKeys() {
    const remotes = (await pullRelay('keys')) || [];
    remotes.forEach((key: any) => {
      key.isRemote = true;
      key.isSyncing = keys?.every(
        (it) => it.priKey !== key.priKey || it.pubKey !== key.pubKey
      );
    });
    const newKeys = remotes.concat(
      keys
        ?.filter(
          (local) =>
            local.enabled &&
            remotes.every(
              (remote: any) =>
                remote.priKey !== local.priKey || remote.pubKey !== local.pubKey
            )
        )
        .map((it) => {
          return { ...it, isSyncing: true };
        })
    );
    setSyncKeys(newKeys);
  }

  async function syncKeys() {
    const options = await db.getOptions();
    for (const key of syncDataKeys?.filter(
      (it) => it.isSyncing && it.isRemote
    ) || []) {
      if (
        keys?.every(
          (it) => it.priKey !== key.priKey || it.pubKey !== key.pubKey
        )
      ) {
        // 本地没有的需要增加
        await db.addKey(key.priKey, key.pubKey);
      }
    }
    if (syncFolders?.some((it) => it.isSyncing && !it.isRemote)) {
      await pushRelay(
        'keys',
        syncDataKeys!.map(({ priKey, pubKey }) => ({
          priKey,
          pubKey,
          clientId: options?.clientId,
        }))
      );
    }
  }

  const syncCount =
    (syncFolders?.filter((it) => it.isSyncing && it.cid).length || 0) +
    (syncDataKeys?.filter((it) => it.isSyncing).length || 0);

  return (
    <>
      {contextHolder}
      <p>
        登录数据中转站,自动同步相同账户间的记事本目录和秘钥设置,快速数据共享。
      </p>
      {user && (
        <p>
          已经登录: {user.name}
          <Button
            type="link"
            onClick={() => {
              trackClick('logout', '退出登录');
              db.logout();
            }}
          >
            退出
          </Button>
        </p>
      )}
      <div>
        {user ? (
          <Space>
            <Popconfirm
              title="同步数据"
              description={
                syncFolders ? (
                  <>
                    <span
                      title={syncFolders
                        .filter((it) => it.isSyncing && it.cid)
                        .map((it) => it.name)
                        .join(',')}
                    >
                      {syncCount > 0
                        ? `可以同步${[
                            syncFolders.filter((it) => it.isSyncing && it.cid)
                              .length > 0 &&
                              `${
                                syncFolders.filter(
                                  (it) => it.isSyncing && it.cid
                                ).length
                              }个记事本`,
                            syncDataKeys.filter((it) => it.isSyncing).length &&
                              `${
                                syncDataKeys.filter((it) => it.isSyncing).length
                              }个秘钥`,
                          ]
                            .filter((hasCount) => hasCount)
                            .join(',')}?`
                        : '无需同步'}
                    </span>
                    {syncFolders.filter((it) => it.isSyncing && !it.cid)
                      .length ? (
                      <span
                        title={syncFolders
                          .filter((it) => it.isSyncing && !it.cid)
                          .map((it) => it.name)
                          .join(',')}
                      >
                        (
                        {`${
                          syncFolders.filter((it) => it.isSyncing && !it.cid)
                            .length
                        }个记事本文件夹未在IPFS网络保存无法同步`}
                        )
                      </span>
                    ) : null}
                  </>
                ) : error ? (
                  '获取数据失败:' + error.message
                ) : (
                  '请稍后正在获取同步列表'
                )
              }
              okButtonProps={{
                disabled: syncCount === 0,
              }}
              onConfirm={() => {
                startSyncData()
                  .then(() => {
                    messageApi.success('文件夹同步完成');
                  })
                  .catch((err) => messageApi.error(err.message));
              }}
              okText="确定"
              cancelText="取消"
              onOpenChange={(open) => {
                setError(null);
                open && trackClick('fetch_folders', '获取同步数据');
                open &&
                  fetchRemoteData().catch((err) => {
                    setError(err);
                  });
              }}
            >
              <Button>同步数据</Button>
            </Popconfirm>
            <Button
              type="text"
              danger
              onClick={() => {
                trackClick('clearRelay', '清除数据');
                deleteRelay('folders');
                deleteRelay('keys');
                messageApi.success('清除成功');
              }}
            >
              清除数据
            </Button>
          </Space>
        ) : (
          <Button
            onClick={() => {
              trackClick('login', '登录账户');
              (window as any).showLogin();
            }}
          >
            登录账户
          </Button>
        )}
      </div>
    </>
  );
}
