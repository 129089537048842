import { Button, Modal, message } from 'antd';
import { useEffect, useState } from 'react';
import { db } from './Data';
import { trackClick } from './tracker'; 

export default function ({
  open,
  mode,
  onClose,
}: {
  mode: string; //  'add' | 'create'
  open: boolean;
  onClose: () => void;
}) {
  const [newMode, setMode] = useState(mode);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMode(mode);
  }, [mode]);

  function handleClose() {
    setLoading(false);
    setTimeout(() => {
      onClose && onClose();
      setMode(mode);
    }, 0);
  }

  async function addNewFolder() {
    try {
      await db.createEmptyBook('');
      handleClose();
      setMode('create');
    } catch (err) {
      message.error((err as Error).message);
    }
  }

  const footer = [];
  if (newMode === 'add' || newMode === 'add_only') {
    footer.push(
      newMode === 'add' && (
        <Button
          key="new"
          onClick={() => {
            trackClick('new_folder', '创建新文件夹');
            addNewFolder();
          }}
        >
          创建新文件夹
        </Button>
      ),
      <Button
        key="back"
        onClick={() => {
          trackClick('back_folder', '取消');
          handleClose();
        }}
      >
        取消
      </Button>,
      <Button
        key="submit"
        type="primary"
        loading={loading}
        onClick={() => {
          trackClick('submit_sync', '开始同步');
        }}
      >
        开始同步
      </Button>
    );
  }
  if (newMode === 'create') {
    footer.push(
      <Button
        key="back"
        onClick={() => {
          trackClick('cancel_folder', '取消');
          handleClose();
        }}
      >
        取消
      </Button>,
      <Button
        key="new"
        type={'primary'}
        onClick={() => {
          trackClick('create_folder', '创建新文件夹');
          addNewFolder();
        }}
      >
        创建新文件夹
      </Button>
    );
  }
  const title = newMode === 'add_only' ? '同步' : '添加';
  return (
    <Modal
      open={open}
      title={title + '记事本'}
      onOk={() => {
        trackClick('create_folder_ok', '新文件夹');
        addNewFolder();
      }}
      onCancel={() => {
        trackClick('cancel_folder_modal', '取消创建文件夹');
        handleClose();
      }}
      footer={footer}
    >
      <p>{title}一个去中心化网络(IPFS)上的文件夹作为当前记事本</p>
    </Modal>
  );
}
