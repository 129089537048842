import { Collapse, ConfigProvider } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars-2';
const { Panel } = Collapse;
import './Settings.less';
// import { SyncCode } from './Settings/SyncCode';
import { RsaKey } from './Settings/RsaKey';
import { BookSetting } from './Settings/BookSetting'; 
import { UserSync } from './Settings/UseSync';

const customizeRenderEmpty = () => '未添加设置';

export default function Settings({ onPPKAdd, onFolderAdd }: any) {
  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <div className="ipfs-settings">
        <Scrollbars autoHide>
          <Collapse ghost>
            <Panel header="记事本管理" key="ipfsnode">
              <BookSetting onFolderAdd={onFolderAdd}></BookSetting>
            </Panel>
            <Panel header="数据安全" key="rsapk">
              <RsaKey onPPKAdd={onPPKAdd}></RsaKey>
            </Panel>
            <Panel header="数据中转站" key="acc">
              <UserSync></UserSync>
            </Panel>
            {/* <Panel header="数据同步" key="synccode">
              <SyncCode onFolderAdd={onFolderAdd}></SyncCode>
            </Panel>  */}
          </Collapse>
        </Scrollbars>
      </div>
    </ConfigProvider>
  );
}
