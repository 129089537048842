import { useCallback, useEffect, useRef, useState } from 'react';
import './Login.less';
import { message } from './message';
import axios from 'axios';
import { Avatar, Button, Checkbox, Drawer } from 'antd';
import { useLiveQuery } from 'dexie-react-hooks';
import { WechatOutlined } from '@ant-design/icons';
import { db } from './Data';

const ua = window.navigator.userAgent.toLowerCase();
const isWeixin = ua.indexOf('micromessenger') !== -1;
const puls = (window as any).plus;
const DEBUGLOCAL = 'local' + location.port;

const loginApi = axios.create({
  baseURL: process.env.LOGIN_PATH,
});

export async function refershToken(token: string) {
  const LOGINURL = `${process.env.LOGIN_SERVER}/refresh`;
  const res = await axios.post(LOGINURL, {
    token,
  });
  return res.data;
}

const loginUrl = process.env.LOGIN_PAGE;

async function loadToken() {
  // 如果url的query里有token,直接登录
  if (window.location.search.includes('token=')) {
    const token = new URLSearchParams(window.location.search).get('token');
    if (token) {
      await db.login(token);
      // 然后重置url去掉token
      window.history.replaceState(
        null,
        window.location.pathname + window.location.hash
      );
    }
  }
}

async function checkRefershToken() {
  try {
    // 检查user的token是否要过期，过期前7天刷新token
    const user = await db.getUser();
    if (
      user &&
      new Date().getTime() >
        user.expiredAt.getTime() - 7 * 24 * 60 * 60 * 1000 &&
      new Date().getTime() < user.expiredAt.getTime()
    ) {
      const newToken = await refershToken(user.token);
      await db.login(newToken);
    }
  } catch (err) {
    console.log(err);
  }
}

let sent = false;

function LoginPlus({ onSuccess, onClose }: any) {
  const [services, setServices] = useState<any>([{}]);
  const [appid, setAppid] = useState('');
  const [state, setState] = useState('');
  const user = useLiveQuery(() => db.getUser());
  const [agree, setAgree] = useState(false);
  const [serverUrl, setServerUrl] = useState('');
  const ref = useRef<any>(null);

  useEffect(() => {
    loginApi.get('/config?app=app', { withCredentials: true }).then((res) => {
      setState(res.data.state);
      setServerUrl(res.data.serverUrl);
      setAppid(res.data.appid);
    });
  }, []);

  useEffect(() => {
    // 获取登录鉴权服务列表，单独保存微信登录鉴权服务对象
    puls?.oauth?.getServices(
      function (services: any) {
        setServices(services);
      },
      function (e: any) {
        puls.nativeUI.alert('获取登录鉴权服务列表失败：' + JSON.stringify(e));
      }
    );
  }, []);

  // 获取微信登录授权对象后可进行授权操作
  const authorize = useCallback(
    function (service: any) {
      if (!agree) {
        // 添加shake类来触发动画
        ref.current!.classList.add('shake');

        // 动画结束后移除shake类，以便下次点击时可以再次触发动画
        ref.current!.addEventListener(
          'animationend',
          function handleAnimationEnd(event: any) {
            if (event.animationName === 'shake') {
              ref.current!.removeEventListener(
                'animationend',
                handleAnimationEnd
              );
              ref.current.classList.remove('shake');
            }
          },
          { once: true }
        ); // 使用{ once: true }确保事件监听器只触发一次
        return;
      }
      service.authorize(
        function (e: any) {
          puls.nativeUI.alert('授权成功：' + JSON.stringify(e));
          loginApi.post(serverUrl, e, { withCredentials: true }).then((res) => {
            onSuccess(res.data.token);
          });
        },
        function (e: any) {
          puls.nativeUI.alert('授权失败：' + JSON.stringify(e));
        },
        { scope: 'snsapi_userinfo', state, appid }
      );
    },
    [onSuccess, agree, appid]
  );

  return (
    <Drawer
      title={<span>登录</span>}
      width={'100%'}
      zIndex={5010}
      open={services.length > 0}
      className="ipfs-notebook-login"
      closable
      onClose={onClose}
    >
      <div className="flex">
        <Avatar
          size={60}
          src={user?.avatar && <img alt="user" src={user?.avatar}></img>}
          style={{ margin: 40 }}
        >
          {user?.name}
        </Avatar>
        {services.map((service: any) => (
          <Button
            type={service.id === 'weixin' ? 'primary' : 'default'}
            icon={service.id === 'weixin' ? <WechatOutlined /> : null}
            size="large"
            style={{ width: '86%' }}
            disabled={!appid}
            onClick={() => authorize(service)}
          >
            {service.description?.includes('登录')
              ? service.description
              : service.description + '登录'}
          </Button>
        ))}
        <p style={{ width: '86%', padding: 5 }} ref={ref}>
          <Checkbox
            checked={agree}
            onChange={(e) => setAgree(e.target.checked)}
          >
            我已阅读并且同意
            <a
              href="https://login.jianguoke.cn/service_agreement.html"
              target="_blank"
            >
              《服务协议》
            </a>
            和
            <a
              href="https://login.jianguoke.cn/privacy_policy.html"
              target="_blank"
            >
              《隐私政策》
            </a>
          </Checkbox>
        </p>
      </div>
    </Drawer>
  );
}

function LoginDialog(props: any) {
  useEffect(() => {
    sent = false;
  }, []);

  useEffect(() => {
    const handle = (event: any) => {
      if (
        event.origin === loginUrl && //确认消息来自于自己的域名 Make sure the message posted from your own origin
        !event.data.source && //过滤掉一些调试组件发来的信息  Filter out some devtools message
        !sent //避免多次运行回调函数  Avoid callback twice
      ) {
        sent = true;
        props.onSuccess(event.data);
      }
    };
    window.addEventListener('message', handle);
    return () => {
      window.removeEventListener('message', handle);
    };
  }, [props]);

  return (
    <iframe
      className="ipfs-notebook-login-dialog"
      frameBorder={0}
      title="登录"
      src={
        loginUrl +
        '?from=' +
        (process.env.NODE_ENV === 'development' ? DEBUGLOCAL : 'note') +
        '&dialog=true'
      }
    ></iframe>
  );
}

function LoginMP(props: any) {
  const [appid, setAppid] = useState('');
  const [state, setState] = useState('');
  const [redirectUri, setRedirectUri] = useState('');

  useEffect(() => {
    loginApi.get('/config?app=mp', { withCredentials: true }).then((res) => {
      setState(res.data.state);
      setRedirectUri(res.data.serverUrl);
      setAppid(res.data.appid);
    });
  }, []);

  useEffect(() => {
    if (!appid) return;
    const params = {
      appid,
      redirect_uri:
        redirectUri +
        (process.env.NODE_ENV === 'development' ? '/' + DEBUGLOCAL : '/note'),
      response_type: 'code',
      scope: 'snsapi_userinfo',
      state: state || '',
    };
    const url =
      'https://open.weixin.qq.com/connect/oauth2/authorize?' +
      new URLSearchParams(params) +
      '#wechat_redirect';
    window.location.href = url;
  }, [appid, redirectUri, state]);

  return null;
}

export function Login() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    (window as any).showLogin = (visible = true) => {
      setVisible(visible);
    };
    loadToken()
      .then(checkRefershToken)
      .catch((err) => {
        message.error(err.message);
      });
    return () => {
      delete (window as any).showLogin;
    };
  }, []);

  const loginWechatRes = useCallback(async (token: string) => {
    if (token === 'disabled' || !token) {
      message.error('账户已禁用');
      (window as any).showLogin(false);
    } else if (token === 'close') {
      (window as any).showLogin(false);
    } else {
      try {
        await db.login(token);
        (window as any).showLogin(false);
      } catch (err) {
        message.error((err as any).message);
      }
    }
  }, []);

  return visible ? (
    puls ? (
      <LoginPlus
        onSuccess={loginWechatRes}
        onClose={() => (window as any).showLogin(false)}
      />
    ) : isWeixin ? (
      <LoginMP
        onSuccess={loginWechatRes}
        onClose={() => (window as any).showLogin(false)}
      />
    ) : (
      <LoginDialog
        onSuccess={loginWechatRes}
        onClose={() => (window as any).showLogin(false)}
      />
    )
  ) : null;
}
