import { useEffect, useState } from 'react';
import { Button, Drawer } from 'antd';
import {
  FullscreenExitOutlined,
  FullscreenOutlined,
  RestOutlined,
} from '@ant-design/icons';
import { useLiveQuery } from 'dexie-react-hooks';
import { Agent } from '.';
import { db } from '../Data';
import { getBrowserWidth } from '../utils';

const win = window as any;


function handleChildrenClose() {
  db.showAgent(false);
}


export function AgentSlider() {
  const options = useLiveQuery(() => db.agent.toCollection().first()); 
  const [isOpen, setChildrenDrawer] = useState(false);
  const [size, setSize] = useState(getBrowserWidth());

  const resizeClick = () => {
    const checked = options?.size === 'large' ? false : true;
    db.setAgent({ size: checked ? 'large' : 'default' });
  };

  async function handleClear() { 
    await db.clearMessages();
    await db.setSid('');
  }

  useEffect(() => {
    setChildrenDrawer(!!options?.visible);
    if (options?.visible && size === 'xs') {
      win.history.pushState(null, null, win.location.href);
      win.addEventListener('popstate', handleChildrenClose);
      return () => {
        win.removeEventListener('popstate', handleChildrenClose);
      };
    }
    // 监听窗口resize事件
    function setWidth() {
      setSize(getBrowserWidth());
    }
    win.addEventListener('resize', setWidth);
    return () => {
      win.removeEventListener('resize', setWidth);
    };
  }, [options?.visible]);

  return (
    <Drawer
      title={<span>AI助手</span>}
      width={size === 'xs' ? '100%' : undefined}
      size={size === 'xs' ? undefined : options?.size} 
      extra={
        <>
          <Button
            className="ipfs-notebook-agent-clear"
            icon={<RestOutlined />}
            type="text"
            title="清空"
            onClick={() => handleClear()}
          ></Button>
          {size !== 'xs' && (
            <Button
              className="code-teacher-size"
              size="small"
              type="text"
              title="大小"
              icon={
                options?.size !== 'large' ? (
                  <FullscreenOutlined />
                ) : (
                  <FullscreenExitOutlined />
                )
              }
              onClick={resizeClick}
            />
          )}
        </>
      }  
      className="ipfs-notebook-agent-slider"
      closable={size === 'xs'}
      onClose={handleChildrenClose}
      open={isOpen}
      afterOpenChange={(open) => {
        if (open) {
          (
            win.document.querySelector('.ipfs-notebook-agent-input') as any
          )?.focus();
        }
      }}
    >
      <Agent
      ></Agent>
    </Drawer>
  );
}
