// 引入crypto-js库
import * as CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
import { Key } from './Data';

export function download(filename: string, text: string) {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function getBrowserWidth() {
  if (window.innerWidth < 768) {
    return 'xs';
  } else if (window.innerWidth < 991) {
    return 'sm';
  } else if (window.innerWidth < 1199) {
    return 'md';
  } else {
    return 'lg';
  }
}

export function createPem(pubKey: string, priKey: string) {
  return (
    (pubKey.includes('PUBLIC KEY')
      ? ''
      : '-----BEGIN RSA PUBLIC KEY-----\r\n') +
    pubKey +
    (pubKey.includes('PUBLIC KEY')
      ? ''
      : '\r\n-----END RSA PUBLIC KEY-----\r\n') +
    (priKey.includes('PRIVATE KEY')
      ? ''
      : '-----BEGIN RSA PRIVATE KEY-----\r\n') +
    priKey +
    (priKey.includes('PRIVATE KEY') ? '' : '\r\n-----END RSA PRIVATE KEY-----')
  );
}

export function loadPem(txt: string, comment = true) {
  const lines = txt
    .split('\n')
    .filter((notemptyline) => notemptyline)
    .map((txt) => txt.replace('\r', ''));
  let isPub = false;
  let isPri = false;
  const keys = { public: '', private: '' };
  for (const line of lines) {
    if (
      line.startsWith('--') &&
      line.includes('PUBLIC KEY') &&
      line.endsWith('--')
    ) {
      isPub = true;
      isPri = false;
      if (!comment) {
        continue;
      }
    }
    if (
      line.startsWith('--') &&
      line.includes('PRIVATE KEY') &&
      line.endsWith('--')
    ) {
      isPri = true;
      isPub = false;
      if (!comment) {
        continue;
      }
    }
    if (isPub) {
      if (keys.public) {
        keys.public += '\n';
      }
      keys.public += line;
    }
    if (isPri) {
      if (keys.private) {
        keys.private += '\n';
      }
      keys.private += line;
    }
  }
  return keys;
}

export function getReasonText(reason: string | undefined) {
  switch (reason) {
    case 'nokey':
      return '没有秘钥无法加密存储';
    case 'cidconflict':
      return '远程更新冲突';
    case 'nobook':
      return '记事本未找到';
    case 'success':
      return '';
    case undefined:
      return '';
    default:
      return reason;
  }
}

export function formatStringLen(
  strVal: string,
  len: number,
  padChar: string = ' '
) {
  if (!strVal) {
    return padChar.repeat(len);
  } else {
    const strLen = strVal.length;
    if (strLen > len) {
      return strVal.substring(0, len);
    } else if (strLen < len) {
      return strVal.padEnd(len, padChar);
    } else {
      return strVal;
    }
  }
}

// 加密函数
export function contentEncrypted(buff: string, keyPair: Key): string {
  try {
    const key = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex);
    const iv = CryptoJS.lib.WordArray.random(8).toString(CryptoJS.enc.Hex);
    const ekey = encryptRSA(key, keyPair.pubKey);
    const ebuff = encryptAES(buff, key, iv);

    const content = CryptoJS.enc.Utf8.parse(keyPair.name.padEnd(10, '\0'))
      .concat(CryptoJS.enc.Utf8.parse(ekey))
      .concat(CryptoJS.enc.Utf8.parse(iv))
      .concat(CryptoJS.enc.Hex.parse(ebuff));
 
    console.log('key:', key, 'iv:', iv, 'ekey:', ekey.length);
    console.log('contents:', buff.length, 'encrypted:', ebuff.length);
    // console.log(content.toString(CryptoJS.enc.Base64))
    console.log(' ');

    return content.toString(CryptoJS.enc.Base64);
  } catch (err) {
    console.log(err);
    throw err;
  }
}

// 解密函数
export async function contentDecrypted(
  buff: string,
  keyPair: Key
): Promise<string> {
  const keyPairNameLen = 10; 
  const base64Buff = CryptoJS.enc.Base64.parse(buff);
  const key = decryptRSA(
    base64Buff
      .toString(CryptoJS.enc.Utf8)
      .slice(keyPairNameLen, keyPairNameLen + 684),
    keyPair.priKey
  );
  const iv = base64Buff
    .toString(CryptoJS.enc.Utf8)
    .slice(keyPairNameLen + 684, keyPairNameLen + 684 + 16);
  const econtent = base64Buff
    .toString(CryptoJS.enc.Hex)
    .slice(keyPairNameLen + 684 + 16);
  //  const ebuf = CryptoJS.enc.Hex.parse(econtent);
  const content = decryptAES(econtent, key, iv);

  console.log(' ');
  console.log('DECRYPTION --------');
  console.log('key:', key, 'iv:', iv);
  console.log('contents:', content.length, 'encrypted:', econtent.length);

  return content;
}

// AES加密函数
function encryptAES(buffer: string, secretKey: string, iv: string): string {
  const ivWordArray = CryptoJS.enc.Hex.parse(iv);
  const encrypted = CryptoJS.AES.encrypt(buffer, secretKey, {
    iv: ivWordArray,
    mode: CryptoJS.mode.CTR,
  });
  return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
}

// AES解密函数
function decryptAES(buffer: string, secretKey: string, iv: string): string {
  const ivWordArray = CryptoJS.enc.Hex.parse(iv);
  const decrypted = CryptoJS.AES.decrypt(buffer, secretKey, {
    iv: ivWordArray,
    mode: CryptoJS.mode.CTR,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

// RSA加密函数
function encryptRSA(toEncrypt: string, publicKey: string): string {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  const encrypted = encrypt.encrypt(toEncrypt);
  if (encrypted === false) {
    throw new Error('RSA加密失败');
  }
  return encrypted;
}

// RSA解密函数
function decryptRSA(toDecrypt: string, privateKey: string): string {
  const encrypt = new JSEncrypt();
  encrypt.setPrivateKey(privateKey);
  const decrypted = encrypt.decrypt(toDecrypt);
  if (decrypted === false) {
    throw new Error('RSA解密失败');
  }
  return decrypted;
}

export async function generateKeyPair() {
  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: 'RSA-OAEP',
      modulusLength: 2048,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: 'SHA-256',
    },
    true,
    ['encrypt', 'decrypt']
  );

  const publicKey = await window.crypto.subtle.exportKey(
    'spki',
    keyPair.publicKey
  );
  const privateKey = await window.crypto.subtle.exportKey(
    'pkcs8',
    keyPair.privateKey
  );

  return {
    private: btoa(String.fromCharCode(...new Uint8Array(privateKey))),
    public: btoa(String.fromCharCode(...new Uint8Array(publicKey))),
  };
}
