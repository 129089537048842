import Dexie, { IndexableType, Table } from 'dexie';
import shortid from 'shortid';
import { generateKeyPair, getBrowserWidth, loadPem } from './utils';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';
import 'dayjs/plugin/quarterOfYear';

export interface Book {
  id?: number;
  name: string;
  title?: string;
  url: string; // todo 废弃
  enabled: boolean;
  createAt: Date;
  updateAt: Date;
  deleteAt?: Date;
  cid?: string; // ipfs CID
  reason?: 'nokey' | 'cidconflict' | 'success';
  syncAt?: Date;
  isActived: boolean;
  activedAt?: Date;
}

export interface Menu {
  title: string;
  lastAt: Date;
  summary: string;
}

export interface Note {
  id?: number;
  bookId?: number;
  name: string;
  content: string;
  type?: 'text' | 'task';
  enabled: boolean;
  createAt: Date;
  updateAt?: Date;
  deleteAt?: Date;
  syncAt?: Date;
  cid?: string; // ipsf CID
  reason?: 'nokey' | 'cidconflict' | 'nobook' | 'success';
  sid?: number; // ai对话id
}

export interface Key {
  id?: number;
  name: string;
  pubKey: string;
  priKey: string;
  enabled: boolean;
  createAt: Date;
  deleteAt?: Date;
}

export interface Node {
  url: string;
  createAt: Date;
  enabled?: boolean;
}

export interface Option {
  id?: number;
  clientId?: string;
  bookWidth?: number;
  bookVisible?: boolean;
  menuWidth?: number;
  menuVisible?: boolean;
  activeNoteId?: number;
  syncMin?: number; // 同步时间间隔
  firstOpen?: boolean; // 是否不是首次打开
  taskStyle?: 'list' | 'card';
  listCols?: number; // 任务列表显示列数
  cardCols?: number; // 任务列表显示列数
  showFinished?: boolean; // 显示已完成的任务
  showLogin?: boolean; // 是否显示登录
  agentWidth?: number;
}

export interface Agent {
  id?: number;
  speeking?: boolean;
  sid?: string;
  fid?: string; // 来源id，例如note.id
  visible?: boolean;
  fix?: boolean; // 固定模式
  size?: 'default' | 'large';
  inputMode?: string;
}

export interface ChatMessage {
  id?: number;
  message: string;
  type: 'agent' | 'user' | string;
  createdAt: Date;
  state?: 'waitting' | 'output' | 'success';
  tags?: string[];
  like?: boolean;
  sid?: string;
  fid?: string;
}

export interface User {
  id: number;
  name?: string;
  // 头像
  avatar?: string;
  token: string;
  role?: string;
  expiredAt: Date;
}

export function getDateNow() {
  // TODO 时间服务器
  return new Date();
}

export function getDateRange(range: DateRanges): [dayjs.Dayjs, dayjs.Dayjs] {
  const today = dayjs();
  const oneDay = 86400000; // 一天的毫秒数

  switch (range) {
    case DateRanges.Today:
      return [today.startOf('day'), today.endOf('day')];
    case DateRanges.Yesterday:
      return [
        today.subtract(1, 'day').startOf('day'),
        today.subtract(1, 'day').endOf('day'),
      ];
    case DateRanges.LastWeek:
      return [
        today.subtract(1, 'week').startOf('week'),
        today.subtract(1, 'week').endOf('week'),
      ];
    case DateRanges.ThisWeek:
      return [today.startOf('week'), today.endOf('week')];
    case DateRanges.LastMonth:
      return [
        today.subtract(1, 'month').startOf('month'),
        today.subtract(1, 'month').endOf('month'),
      ];
    case DateRanges.ThisMonth:
      return [today.startOf('month'), today.endOf('month')];
    case DateRanges.LastQuarter:
      // 假设季度从1月开始，可能需要调整以匹配实际业务逻辑
      const lastQuarterStart = today.subtract(1, 'quarter').startOf('quarter');
      const lastQuarterEnd = lastQuarterStart
        .clone()
        .add(3, 'month')
        .subtract(1, 'day');
      return [lastQuarterStart, lastQuarterEnd];
    case DateRanges.ThisQuarter:
      const thisQuarterStart = today.startOf('quarter');
      const thisQuarterEnd = thisQuarterStart
        .clone()
        .add(3, 'month')
        .subtract(1, 'day');
      return [thisQuarterStart, thisQuarterEnd];
    case DateRanges.LastYear:
      return [
        today.subtract(1, 'year').startOf('year'),
        today.subtract(1, 'year').endOf('year'),
      ];
    case DateRanges.ThisYear:
      return [today.startOf('year'), today.endOf('year')];
    case DateRanges.RecentSevenDays:
      return [today.subtract(6, 'day').startOf('day'), today.endOf('day')];
    case DateRanges.RecentFifteenDays:
      return [today.subtract(14, 'day').startOf('day'), today.endOf('day')];
    case DateRanges.RecentOneMonth:
      return [today.subtract(1, 'month').startOf('month'), today.endOf('day')];
    case DateRanges.RecentThreeMonths:
      // 简单的计算，可能需要根据实际月份调整
      return [today.subtract(3, 'month').startOf('month'), today.endOf('day')];
    case DateRanges.RecentSixMonths:
      // 简单的计算，可能需要根据实际月份调整
      return [today.subtract(6, 'month').startOf('month'), today.endOf('day')];
    case DateRanges.RecentOneYear:
      return [today.subtract(1, 'year').startOf('year'), today.endOf('day')];
    default:
      throw new Error(`Invalid date range: ${range}`);
  }
}

export enum DateRanges {
  Today = ':TODAY',
  Yesterday = ':YESTERDAY',
  LastWeek = ':LAST_WEEK',
  ThisWeek = ':THIS_WEEK',
  LastMonth = ':LAST_MONTH',
  ThisMonth = ':THIS_MONTH',
  LastQuarter = ':LAST_QUARTER',
  ThisQuarter = ':THIS_QUARTER',
  LastYear = ':LAST_YEAR',
  ThisYear = ':THIS_YEAR',
  RecentSevenDays = ':RECENT_ONE_WEEK',
  RecentFifteenDays = ':RECENT_FIFTEEN_DAY',
  RecentOneMonth = ':RECENT_ONE_MONTH',
  RecentThreeMonths = ':RECENT_THREE_MONTH',
  RecentSixMonths = ':RECENT_SIX_MONTH',
  RecentOneYear = ':RECENT_ONE_YEAR',
}

export class NoteBookDexie extends Dexie {
  // 'books' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  books!: Table<Book>;
  notes!: Table<Note>;
  keys!: Table<Key>;
  options!: Table<Option>;
  nodes!: Table<Node>;

  agent!: Table<Agent>;
  messages!: Table<ChatMessage>;
  user!: Table<User>;

  async getUser() {
    return await this.user.toCollection().first();
  }

  getActaiveNode() {
    return this.nodes.toCollection().first();
  }

  getActiveBook() {
    return this.books.filter((it) => it.isActived).first();
  }

  getOptions() {
    return this.options.toCollection().first();
  }

  async getActiveNote() {
    const options = await this.getOptions();
    const activeBook = await this.getActiveBook();
    return (
      (await this.notes
        .filter((note) => note.id === options?.activeNoteId)
        .first()) ||
      (await this.notes
        .filter((note) => note.bookId === activeBook?.id)
        .first())
    );
  }

  getActiveKey() {
    return this.keys.filter((key) => key.enabled).first();
  }

  constructor() {
    super('jianguoke.notebook');
    this.version(43).stores({
      books: '++id, name', // Primary key and indexed props
      notes: '++id, name, bookId, content, updateAt, cid, syncAt',
      keys: '++id, name',
      options: '++id',
      nodes: 'url',
      messages: '++id, fid, createdAt',
      agent: '++id',
      user: '++id',
    });
  }

  async init() {
    let isInit = false;
    if ((await this.nodes.count()) <= 0) {
      await this.nodes.add({
        url: process.env.IPFS_SERVER!,
        createAt: getDateNow(),
      });
    } else {
      // 升级服务器地址
      for (const node of await this.nodes
        .filter((node) => node.url === 'https://jianguoke.cn/ipfs')
        .toArray()) {
        await this.nodes.update(node, {
          url: process.env.IPFS_SERVER,
        });
      }
    }
    if ((await this.options.count()) <= 0) {
      isInit = true;
      const size = getBrowserWidth();
      await this.options.add({
        bookVisible: size !== 'xs' && size !== 'sm',
        menuVisible: size !== 'xs',
        syncMin: 10,
      });
    }
    if ((await this.agent.count()) <= 0) {
      await this.agent.add({});
    }
    for (const opt of await this.options.toArray()) {
      if (!opt.clientId) {
        await this.options.update(opt, {
          clientId: shortid.generate(),
        });
      }
    }

    if (isInit) {
      // 自动分配一个默认秘钥
      if ((await this.keys.count()) <= 0) {
        const keys = await generateKeyPair();
        await this.addKey(keys.private, keys.public);
      }

      // 默认添加一个记事本
      if ((await this.books.count()) <= 0) {
        const book = await this.createEmptyBook('');

        if (
          (await this.notes
            .filter((note) => note.bookId === book!.id)
            .count()) <= 0
        ) {
          await this.upsertNote('');
        }
      }
    }
  }

  async setFirstOpen(firstOpen: boolean = false) {
    const opt = await this.getOptions();
    if (!opt) {
      return;
    }
    await this.options.update(opt.id!, {
      firstOpen,
    });
  }

  async setBookWidth(bookWidth: number) {
    const opt = await this.getOptions();
    if (!opt) {
      return;
    }
    await this.options.update(opt.id!, {
      bookWidth,
    });
  }

  async setBookVisible(bookVisible: boolean) {
    const opt = await this.getOptions();
    await this.options.update(opt!.id!, {
      bookVisible,
    });
  }

  async setMenuVisible(menuVisible: boolean) {
    const opt = await this.getOptions();
    await this.options.update(opt!.id!, {
      menuVisible,
    });
  }

  async switchMenuVisible() {
    const opt = await this.getOptions();
    await this.options.update(opt!.id!, {
      menuVisible: !opt!.menuVisible,
    });
  }

  async setMenuWidth(menuWidth: number) {
    const opt = await this.getOptions();
    await this.options.update(opt!.id!, {
      menuWidth,
    });
  }

  async setAgentWidth(agentWidth: number) {
    const opt = await this.getOptions();
    await this.options.update(opt!.id!, {
      agentWidth,
    });
  }

  async createEmptyBook(title: string) {
    let currentNode: any = await this.getActaiveNode();
    if (!currentNode?.url) {
      // throw new Error('IPFS接入节点未知,需要再设置中添加');
      currentNode = { url: process.env.IPFS_SERVER };
    }
    const id = await this.books.add({
      ...currentNode,
      name: shortid.generate(),
      enabled: true,
      createAt: getDateNow(),
      updateAt: getDateNow(),
      isActived: false,
      title,
    });
    await this.changeBook(id);
    return await this.books.get(id);
  }

  async addBook(name: string, title?: string, createAt?: Date) {
    const currentNode = await this.getActaiveNode();
    if (!currentNode?.url) {
      throw new Error('IPFS接入节点未知,需要再设置中添加');
    }
    const id = await this.books.add({
      ...currentNode,
      name,
      title,
      enabled: true,
      createAt: createAt || getDateNow(),
      updateAt: getDateNow(),
      isActived: false,
    });
    await this.changeBook(id);
    return id;
  }

  async changeBook(id: IndexableType) {
    const books: Book[] = [];
    await this.books.each((book) => {
      book.isActived = book.id === id;
      books.push(book);
    });
    await this.books.bulkPut(books);
    this.activeNote(-1);
  }

  async updateBookTitle(book: Book) {
    await this.books.update(book, {
      title: book.title,
      updateAt: getDateNow(),
    });
  }

  async updateBookCID(bookId: IndexableType, cid: string) {
    await this.books.update(bookId, {
      cid: cid,
      updateAt: getDateNow(),
    });
  }

  async createNode(url: string) {
    await this.nodes.add({ url, createAt: getDateNow() });
  }

  async deleteNode(node: Node) {
    await this.nodes.delete(node.url);
  }

  async deleteBook(id: IndexableType) {
    const activeBook = await this.getActiveBook();
    const needChange = activeBook?.id === id;
    await this.books.delete(id);
    const books = this.books.filter((book) => book.enabled);
    if (needChange && (await books.count()) > 0) {
      await this.books.update((await books.first())!.id!, {
        isActived: true,
      });
    }
  }

  async activeNote(id: IndexableType) {
    this.options.update((await this.getOptions())!.id!, {
      activeNoteId: id,
    });
    const note = await this.notes.get(id);
    if (note) {
      const agent = await this.agent.toCollection().first();
      if (agent) {
        this.agent.update(agent, {
          sid: note.sid,
          fid: note.id,
        });
      }
    }
  }

  async findNote(query: {
    filters: [
      {
        name: string;
        value: DateRanges | string;
      }
    ];
    limit: number;
    offset: number;
    order: [
      {
        name: string;
        direction: 'asc' | 'desc';
      }
    ];
  }) {
    if (query.order.length >= 2) {
      throw new Error('暂时不支持两个及以上字段排序');
    }

    const coll = query.order
      ? this.notes.orderBy(query.order.map((it) => it.name))
      : this.notes.toCollection();

    if (query.order.some((it) => it.direction === 'desc')) {
      coll.reverse();
    }

    if (query.filters) {
      coll.filter((note: any) => {
        return query.filters.every(({ name, value }) => {
          if (name === 'updateAt' || name === 'createAt') {
            const [start, end] = getDateRange(value as DateRanges);
            return note[name] >= start.toDate() && note[name] <= end.toDate();
          } else if (name === 'name' || name === 'content') {
            return note[name].includes(value);
          } else {
            throw new Error('不支持的过滤条件');
          }
        });
      });
    }

    return await coll
      .limit(query.limit || 10)
      .offset(query.offset || 0)
      .toArray();
  }

  async addNote(note: Note) {
    await this.notes.add({
      ...note,
      enabled: true,
      createAt: note.createAt || getDateNow(),
      updateAt: note.updateAt || getDateNow(),
      syncAt: note.cid ? getDateNow() : undefined,
    });
  }

  async upsertNote(
    content: string,
    id?: IndexableType,
    cid?: string,
    type: 'text' | 'task' = 'text'
  ) {
    let note = await this.notes.filter((it) => it.id === id).first();
    if (!note) {
      const activeBook = await this.getActiveBook();
      if (!activeBook) {
        throw new Error('记事本不存在, 请先创建一个记事本');
      }
      note = {
        name: shortid.generate(),
        type,
        content,
        enabled: true,
        bookId: activeBook?.id!,
        createAt: getDateNow(),
        updateAt: getDateNow(),
        cid: cid || '',
        syncAt: cid ? getDateNow() : undefined,
      };
      const id = await this.notes.add(note);
      await this.activeNote(id);
    } else {
      await this.notes.update(note, {
        // type,
        content,
        updateAt: getDateNow(),
        cid: cid || note.cid,
        syncAt: cid ? getDateNow() : note.syncAt,
      });
    }
  }

  async deleteNote(id: IndexableType) {
    const note = await this.notes.get(id);
    if (!note) {
      return;
    }
    if (note.cid) {
      // 逻辑删除，需要同步ipfs后彻底删除
      await this.notes.update(id, {
        enabled: false,
        updateAt: getDateNow(),
        deleteAt: getDateNow(),
      });
    } else {
      await this.notes.delete(id);
    }
  }

  async upsertKey(priKey: string, pubKey: string) {
    const exists = await this.keys
      .filter(
        (key) =>
          loadPem(key.priKey, false).private === loadPem(priKey, false).private
      )
      .first();
    if (exists) {
      return exists.name;
    }
    return await this.addKey(priKey, pubKey);
  }

  async addKey(priKey: string, pubKey: string) {
    const exists = await this.keys
      .filter(
        (key) =>
          loadPem(key.priKey, false).private === loadPem(priKey, false).private
      )
      .first();
    if (exists) {
      throw new Error('秘钥已经存在');
    }
    const name = shortid.generate();
    await this.keys.add({
      name,
      priKey,
      pubKey,
      enabled: true,
      createAt: getDateNow(),
    });
    return name;
  }

  async deleteKey(id: IndexableType) {
    await this.keys.delete(id);
  }

  async resyncNote(note: Note | IndexableType) {
    await this.notes.update(note, {
      reason: '',
    });
  }

  async syncNote(note: Note) {
    if (!note.enabled && note.reason === 'success') {
      return await this.notes.delete(note.id!);
    }
    await this.notes.update(note, {
      cid: note.cid,
      bookId: note.bookId,
      reason: note.reason,
      syncAt: getDateNow(),
    });
  }

  async resyncBook(book: Book) {
    await this.books.update(book, {
      reason: '',
    });
  }

  async syncBook(book: Book) {
    if (!book.enabled && book.reason === 'success') {
      await this.books.delete(book.id!);
      const ids = await this.notes
        .where('bookId')
        .equals(book.id!)
        .primaryKeys();
      console.log('remove notes...', ids);
      await this.notes.bulkDelete(ids);
      return;
    }
    await this.books.update(book, {
      cid: book.cid,
      reason: book.reason,
      syncAt: getDateNow(),
    });
  }

  async setTaskLayout(layout: {
    taskStyle?: string;
    listCols?: number;
    cardCols?: number;
    showFinished?: boolean;
  }) {
    const opt = await this.getOptions();
    if (!opt) {
      return;
    }
    await this.options.update(opt.id!, layout);
  }

  async setAgent(props: { [key: string]: any }) {
    const agent = await this.agent.toCollection().first();
    await this.agent.update(agent!.id!, props);
  }

  async setSid(sid: string) {
    const opt = await this.agent.toCollection().first();
    if (opt?.sid === sid) {
      return;
    }
    await this.agent.update(opt!.id!, {
      sid,
    });
    const note = await this.getActiveNote();
    if (note) {
      await this.notes.update(note, {
        sid,
      });
    }
  }

  async clearMessages() {
    await this.messages.clear();
  }

  async showAgent(visible: boolean = true) {
    const opt = await this.agent.toCollection().first();
    if (opt?.visible === visible) {
      return;
    }
    await this.agent.update(opt!.id!, { visible });
  }

  async switchAgent() {
    const opt = await this.agent.toCollection().first();
    const note = await this.getActiveNote();
    await this.agent.update(opt!.id!, {
      visible: !opt!.visible,
      sid: note?.sid,
      fid: note?.id,
    });
  }

  async showLogin(showLogin: boolean = true) {
    const opt = await this.getOptions();
    await this.options.update(opt!.id!, { showLogin });
  }

  async login(token: string) {
    const decoded = jwtDecode(token) as any;

    if (decoded) {
      const cur = await this.getUser();
      if (cur?.id && cur?.id === decoded.id) {
        this.user.update(cur.id, {
          token,
          expiredAt: new Date(decoded.exp! * 1000),
        });
        return;
      }
      const user: User = {
        id: decoded.id,
        name: decoded.name,
        avatar: decoded.avatar,
        token,
        expiredAt: new Date(decoded.exp! * 1000),
      };
      await this.user.clear();
      await this.user.add(user);
    } else {
      throw new Error('无效的令牌');
    }
  }

  async logout() {
    await this.user.clear();
  }
}

export const db = ((window as any).data = new NoteBookDexie());
