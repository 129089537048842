import { AgentToolCall } from './api';

export function WaitProcess({ action }: { action?: AgentToolCall }) {
  return (
    <span className="ipfs-notebook-agent-waitting">
      {action?.actionName === 'read_note'
        ? '读取笔记'
        : action?.actionName === 'write_note'
        ? '修改笔记'
        : action?.actionName === 'find_note'
        ? '查找笔记'
        : action?.actionName === 'exe_command'
        ? '执行中'
        : ''}
    </span>
  );
}
