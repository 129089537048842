import { Button, Input } from 'antd';
import { useState } from 'react';
import { ClearOutlined } from '@ant-design/icons'; 
import { message } from '../message';

const { TextArea } = Input;

// export const sendAudio = new Audio('/static/send.mp3');

/**
 * 嗖一声
 *
 * @export
 */
export function sendSuo() {
  // sendAudio.pause();
  // sendAudio.currentTime = 0;
  // sendAudio.play();
}

export function AudioInput(props: {
  disabled?: boolean;
  onClear: () => Promise<void>;
  onEnter: (text: string) => Promise<void>;
}) {
  const [input, setInput] = useState('');

  const sendClick = () => {
    sendSuo();
    props.onEnter(input);
    setInput('');
  };

  const clearClick = () =>{
    props.onClear();
    message.success('对话已经重新开始');
  }

  function handleInput(e: any) {
    setInput(e.target.value);
  }

  return (
    <div className={'ipfs-notebook-agent-question'}>
      <TextArea
        className={'ipfs-notebook-agent-input'}
        autoFocus
        value={input}
        autoSize={{ minRows: 2, maxRows: 10 }}
        allowClear
        bordered={false}
        placeholder="按Shift+Enter换行输入"
        readOnly={props.disabled}
        onKeyDown={(e) => {
          if (props.disabled) return;
          if (e.code === 'Enter' && !(e.shiftKey || e.ctrlKey || e.altKey)) {
            e.preventDefault();
            sendSuo();
            props.onEnter(input);
            setInput('');
          }
        }}
        onChange={handleInput}
      />

      <Button
        type="text"
        size="small" 
        className="ipfs-notebook-agent-clear"
        icon={<ClearOutlined />}
        onClick={clearClick}
        title='新建会话'
      ></Button>

      {!!input && (
        <Button
          type="primary"
          size="small"
          disabled={props.disabled}
          className="ipfs-notebook-agent-send"
          onClick={sendClick}
        >
          发送
        </Button>
      )}
    </div>
  );
}
